//import react from 'react';

// GAクライアントIDくっつける場合
//import './modules/ga.tracking.js';

// ドロワー
import Highendrawer from 'highendrawer';
import Glide from '@glidejs/glide';

import tingle from 'tingle.js';
import 'tingle.js/dist/tingle.min.css';


// GAクライアントIDくっつける場合
//import './modules/ga.tracking.js';




document.addEventListener('DOMContentLoaded', (e) => {

    new Glide('.glide', {
        autoplay: 2000,
        type: 'carousel'
    }).mount();
    console.log('xxxxxxxxxxxxxxxxxxx');
    const modal = new tingle.modal({
        footer: true,
        stickyFooter: false,
        closeMethods: ['overlay', 'button'],
        closeLabel: "閉じる",
        cssClass: ['custom-class-1', 'custom-class-2']
    });
    modal.addFooterBtn('閉じる', 'tingle-btn tingle-btn--primary', function() {
        // here goes some logic
        modal.close();
    });
    /**
     * ドロワーメニュー
     * @see https://ym-aozora.github.io/highendrawer/doc/typedef/index.html#static-typedef-Drawer
     */
    const drawer = new Highendrawer({
        element: document.getElementById('js-drawer'),
        duration: 100,
        onOpen: (e) => {
            document.body.setAttribute('style', 'overflow: hidden');
        },
        onClose: (e) => {
            document.body.setAttribute('style', 'overflow: auto');
        }
    });
    document.getElementById('js-drawer-trigger').addEventListener('click', (e) => {
        console.log('click');
        drawer.open();
    });


    const order_modal_content = document.getElementById('js-modal-order');
    const order_modal_buttons = document.querySelectorAll('.action-order');
    for (let i=0; i<order_modal_buttons.length; i++) {
        const btn = order_modal_buttons[i];
        btn.addEventListener('click', (e) => {
            modal.setContent(order_modal_content);
            modal.open();
        });
    }

    const zoom_images = document.querySelectorAll('.article-single-goukaku .question_img');
    console.log(zoom_images);
    for (let i=0; i<zoom_images.length; i++) {
        const image = zoom_images[i];
        image.addEventListener('click', (e) => {
            e.preventDefault();
            const path_to_image = e.target.closest('.question_img').getAttribute('href');
            console.log(path_to_image);

            modal.setContent(`<img src="${path_to_image}" class="scale-auto" />`);
            modal.open();
            return false;
        });
    }


    /**
     * ページ内リンクのスクロール
     *
     * class属性に .inline-linkがDOM要素を対象とする
     * スクロール先は data-move_to でスクロール先のIDを指定する
     *
     * 例) #targetへスムーズスクロールする
     * <span class="inline-link" data-move_to="target">スムーズスクロール</span>
     *
     * @type {NodeListOf<Element>}
     */
    const scroll_navs = document.querySelectorAll('.inline-link');

    console.log(scroll_navs);
    for (let i=0; i<scroll_navs.length; i++) {
        const el = scroll_navs[i];

        el.addEventListener('click', (e) => {
            const move_to = e.target.closest('.inline-link').dataset.move_to;
            const target = document.getElementById(move_to);
            window.scroll({
                top: target.offsetTop,
                behavior: "smooth"
            });
            if (drawer.state === 'open') {
                drawer.close();
            }
        });
    }

    const videos = document.getElementsByClassName('ytv');
    for (let i = 0; i<videos.length; i++) {
        let id = videos[i].dataset.id;
        let image = `//img.youtube.com/vi/${id}/mqdefault.jpg`;
        videos[i].innerHTML = `<img src=${image} alt="" style="width: 100%; height: auto;" alt="ytv-thumb" data-id="${id}" /><img src="/_/assets/ytv.icon.rgb.webp" alt="" class="ytv-play-icon scale-auto" />`;
        videos[i].addEventListener('click', (e) => {
            console.log('aaa');
            let id = e.target.dataset.id;
            modal.setContent(`<div class="video-youtube"><iframe width="700" height="390" src="//www.youtube.com/embed/${id}" frameborder="0" allowfullscreen></iframe></div>`);
            modal.open();

        });
    }


});
